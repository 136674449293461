import './Home.css';

import axios from "axios";
import { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { AppStore } from "app/model/AppStore";
import { PlayerStore } from "widgets/Player/model/PlayerStore";
import { runPlayAfterSwitch } from "widgets/Player";
import { HeaderAccount } from 'widgets/HeaderAccount';
import { Stations } from 'widgets/Stations';
import { Sidebar } from "widgets/Sidebar";
import { Player } from "widgets/Player";
import { CustomStationModel, StationModel } from "entities/Station";
import { ModalActionStore } from "features/PlayerControls/model/ModalActionStore";
import { NotificationList } from "features/Notification";
import { BACKEND_URL, MAIN_PATH, SEO_PATH } from "shared/config/backendPaths";
import { RmdLogo } from "shared/ui/RmdLogo";

import { RmdPage } from '../RmdPage';

export const Home = observer(() => {
  const [metaData, setMetaData] = useState<any[]>([]);
  const location = useLocation();
  const playerStore = PlayerStore.getShared();
  const { playerControlStore } = playerStore;
  const { appMediaStore, authProcess } = AppStore.getShared();
  const actionStore = ModalActionStore.getShared();
  const {
    stationSwitchStore,
    favouriteStore,
    songHistoryStore,
    nowPlayingStore
  } = appMediaStore;
  const { authStore, notificationStore } = authProcess;
  const { isAuth } = authStore;
  const { currentStation, topTracks, myTracks } = stationSwitchStore;
  const handleNonAuthAction = () => authStore.openForm();
  const isCustomStation = currentStation?.isCustom ?? false;

  const getCustomStationData = () => {
    switch (stationSwitchStore.listType) {
      case "personal":
        return myTracks;
      case "history":
        return songHistoryStore.songHistory;
      case "top":
        return topTracks
      default:
        return myTracks;
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      const getSeo = async () => {
        try {
          const response= await axios.get(BACKEND_URL + MAIN_PATH + SEO_PATH + "?page=main");
          setMetaData([...response.data]);
        } catch (e) {
          console.error(e);
        }
      }

      getSeo();
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Random Minimal Deep</title>
        {metaData?.map(
          ({ name, id, content }) => <meta key={id} name={name} content={content}/>
        )}
      </Helmet>
      <RmdPage
        headerLeft={""}
        headerMiddle={<RmdLogo/>}
        headerRight={
          <HeaderAccount authProcess={authProcess} />
        }
        body={
          <main className="home">
            <Stations
              isAuth={isAuth}
              actionAfterSwitch={(
                model: StationModel | CustomStationModel,
                currentStation: StationModel | CustomStationModel | null
              ) => runPlayAfterSwitch(
                  model,
                  currentStation,
                  playerControlStore.isPlaying,
                  playerControlStore.togglePlay,
                (data) => nowPlayingStore.setNowPlayingData(data),
                  stationSwitchStore.myTracks[0],
                )
              }
            />
            <Sidebar.Layout
              tracks={isCustomStation ? myTracks : topTracks}
              isCustomStation={isCustomStation}
              isAuth={isAuth}
              onOpen={() => {
                actionStore.openForm();
                favouriteStore.getLiked();
              }}
              historyTracks={songHistoryStore.songHistory}
              likedSongs={favouriteStore.likedSongs}
              onClose={() => actionStore.closeForm()}
              onNotAuth={handleNonAuthAction}
            />
            <NotificationList store={notificationStore}/>
          </main>
        }
        footer={
          <Player.Layout
            stationTitle={stationSwitchStore.currentStation?.shortTitle ?? ""}
            customStationData={getCustomStationData()}
            handleNonAuthAction={handleNonAuthAction}
            isEqualizerOn
          />
        }
      />
      <Outlet/>
    </Fragment>
  );
});